const counters = document.querySelectorAll('[data-counter-target]');

counters.forEach((counter) => {
    const updateCount = () => {
        const target = parseInt(counter.getAttribute('data-counter-target'));
        const count = parseInt(counter.innerText);

        let countSpeed = parseInt(counter.getAttribute('data-counter-speed'));
        if (isNaN(countSpeed)) {
            countSpeed = 500;
        }

        let countIncrement = parseInt(counter.getAttribute('data-counter-increment'));
        if (isNaN(countIncrement)) {
            countIncrement = 1;
        }

        if (count < target) {
            counter.innerText = count + countIncrement;
            setTimeout(updateCount, countSpeed);
        } else {
            counter.innerText = target;
        }
    };

    const isInViewport = function (elem) {
        let distance = elem.getBoundingClientRect();
        return (
            distance.top >= 0 &&
            distance.left >= 0 &&
            distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            distance.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    window.addEventListener('scroll', function () {
        if (isInViewport(counter)) {
            updateCount();
        }
    }, false);

    if (isInViewport(counter)) {
        updateCount();
    };

});